import React, {Component} from 'react'
import {appDatabasePrimaryFunctions, base} from '../../base';
import appBackgroundImage from '../../styles/images/trivia_background.png';
import AnswerItemsComponent from "../small_components/AnswerItemsComponent";
import {getURLParameter, isObjectEmpty} from "../utils/HelpfulFunction";
import posed, {PoseGroup} from "react-pose";

const Trans = posed.div({
    enter: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: ({ delta }) => - delta * 100 + 'vw' }
})

let interval;

class SideBigScreen extends Component {
    constructor(props) {
        super(props);
        const questionId = getURLParameter('questionid') || "";
        this.state = {
            frozenVotingState: [],
            questions: {},
            currentGame: {},
            isVisible: false,
            rotate: false,
            answered: false,
            userAnswers: [],
            showPercentages: false,
            questionId: questionId,
            question: 0,
            startAnimating: false
        };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        let query = {};
        this.answeredRef = base.bindToState('currentGame/answered', {
            context: this,
            state: 'answered',
        });
        if(this.state.questionId){
            query = {
                orderByChild: 'questionId',
                equalTo: this.state.questionId
            }
        }
        this.userAnswersRef = base.listenTo(`userAnswers`, {
            context: this,
            asArray: true,
            query: query,
            then(data){
                this.setUpQuestionsWithAnswerCount(data, this.state.questionId)
            }
        });
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    startRotationTimer(){
        const variables = this.props.variables || {};
        let seconds = variables.questionDisplayLength || 5;
        let questionAnswers = this.state.questionAnswers || [];
        let questionIndex = this.state.question || 0;
        if(questionAnswers.length === 0 || this.state.questionId){
            return;
        }
        seconds = parseInt(seconds);
        seconds = seconds * 1000;
        if(interval){
            clearInterval(interval);
        }
        interval = setInterval(() => {
            if(questionAnswers.length <= questionIndex + 1){
                questionIndex = 0;
            } else {
                questionIndex++;
            }
            this.setState({
                question: questionIndex,
                startAnimating: false
            }, () => {
                this.setState({
                    startAnimating: true
                })
            })
        }, seconds)
    }

    async getQuestions(questionId){
        let questionRef = appDatabasePrimaryFunctions.ref('currentGame/questions');
        if(questionId){
            questionRef = questionRef.orderByChild('id').equalTo(questionId)
        }
        const questions = await questionRef.once('value');
        const questionsObject = {};
        let order = 1;
        questions.forEach(function(data){
            const question = data.val();
            const questionId = question.id || question.key;
            questionsObject[questionId] = {};
            questionsObject[questionId]['questionText'] = question.questionText;
            questionsObject[questionId]['order'] = order;
            questionsObject[questionId]['answers'] = [];
            questionsObject[questionId]['showScoreboardPercentages'] = question.showScoreboardPercentages;
            const answers = question.answers || [];
            let anotherCount = 1;
            for(const i in answers){
                const answer = answers[i];
                answer['questionId'] = questionId;
                answer['order'] = anotherCount;
                questionsObject[questionId]['answers'].push(answer);
                anotherCount++;
            }
            order++;
        })
        return questionsObject;
    }

    async setUpQuestionsWithAnswerCount(userAnswers, questionId){
        let questions = this.state.questions || {};
        let questionsLength = 0;
        if(!isObjectEmpty(questions)){
            questionsLength = Object.keys(questions).length;
        }
        if(questionsLength === 0){
            questions = await this.getQuestions(questionId);
            questionsLength = Object.keys(questions).length;
        }
        for(const i in userAnswers){
            const userAnswer = userAnswers[i];
            const userAnswerQuestionId = userAnswer.questionId;
            const userAnswerAnswerId = userAnswer.answerId || userAnswer.id || userAnswer.key;
            if(questionId && questionId !== userAnswerQuestionId){
                continue;
            }
            for(const t in questions[userAnswerQuestionId]['answers']){
                if(userAnswerAnswerId === questions[userAnswerQuestionId]['answers'][t].id){
                    if(questions[userAnswerQuestionId]['answers'][t]['count']){
                        questions[userAnswerQuestionId]['answers'][t]['count']++;
                    } else {
                        questions[userAnswerQuestionId]['answers'][t]['count'] = 1;
                    }
                }
            }
        }
        const finalArray = [];
        for(const i in questions){
            let totalResponsesPerQuestion = 0;
            for(const t in questions[i].answers){
                totalResponsesPerQuestion += (questions[i].answers[t].count || 0);
            }
            questions[i]['totalResponse'] = totalResponsesPerQuestion;
            finalArray.push(questions[i])
        }
        this.setState({
            questionAnswers: finalArray,
            startAnimating: true
        }, () => {
            if(questionsLength > 1){
                this.startRotationTimer();
            }
        });
    }

    handleImageLoaded() {
        setTimeout(() => this.setState({
            isVisible: true
        }), 1);
    }

    handleImageErrored() {
        console.log("Image failed to load")
        this.setState({ loading: false });
    }

    componentWillUnmount() {
        base.removeBinding(this.userAnswersRef);
        base.removeBinding(this.answeredRef);
        if(interval){
            clearInterval(interval);
        }
    }

    render() {
        const tenantVariables = this.props.variables || {};
        const currentGame = this.state.currentGame;
        const questionId = this.state.questionId;
        let questionAnswers = this.state.questionAnswers || [];
        const questionIndex = this.state.question || 0;
        let question = questionAnswers[questionIndex] || {};
        if(questionId){
            for(const i in questionAnswers){
                if(questionAnswers[i] === questionIndex){
                    question = questionAnswers[i];
                }
            }
        }
        let questionLeftText = tenantVariables.leftScoreboardQuestionSize || "44";
        questionLeftText = questionLeftText + "px";
        const backgroundImage = tenantVariables.scoreboardBackgroundImage || appBackgroundImage;
        const leftOfScoreboardImage = tenantVariables.leftOfScoreboardImage || "";
        let show_percentages = false;
        if(tenantVariables.showScoreboardPercentages === "all" || !tenantVariables.showScoreboardPercentages){
            show_percentages = true
        } else if (tenantVariables.showScoreboardPercentages === "parttime" && this.state.answered) {
            show_percentages = true
        }
        if(question.showScoreboardPercentages && question.showScoreboardPercentages === "all"){
            show_percentages = true;
        } else if(question.showScoreboardPercentages && question.showScoreboardPercentages === "parttime"){
            show_percentages = this.state.answered;
        } else if(question.showScoreboardPercentages && question.showScoreboardPercentages === "never"){
            show_percentages = false;
        }
        console.log(show_percentages)
        return (
            <div className="grid-wrapper" style={{backgroundImage: "url(" + backgroundImage + ")"}}>
                <div className="grid-container-outer">
                    <div className="grid-left">
                        <div className="creator-awards-logo"><img src={leftOfScoreboardImage} alt=""/></div>
                    </div>
                    <div style={{marginRight: "5%"}}>
                        <h1 style={{color:tenantVariables.progressBarQuestionTextColor, marginBottom:"30px", fontSize:questionLeftText, fontWeight:700, textShadow: "2px 2px #000"}}>{question.questionText}</h1>
                        {this.state.startAnimating &&
                            <PoseGroup animateOnMount={true} delta={1} style={{margin: "auto", width: "100%"}}>
                                <Trans key={233}>
                                    <AnswerItemsComponent gameover={this.state.answered} show_percentages={show_percentages} totalCount={question.totalResponse} question={question} answers={question.answers || []} screenToShow={currentGame.screenToShow} tenantVariables={tenantVariables} fromScoreboard={true} sideBigScreen={true} onClickAnswer={()=>console.log("click")}/>
                                </Trans>
                            </PoseGroup>
                        }
                    </div>
                </div>
            </div>
          )
        }
}

export default SideBigScreen;
